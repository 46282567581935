<template>
  <teleport to="#modals">
    <div class="modal is-active is-layout-fixed" v-if="active" tabindex="0" ref="modalroot">
      <div class="modal-background" @click="this.closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="buttons is-right">
            <button class="button is-secondary is-inverted" @click="this.closeModal()">
              <span class="icon is-medium">
                <i class="fas fa-2x fa-window-close"></i>
              </span>
            </button>
          </div>
        </header>
        <section class="modal-card-body">
          <p class="mt-3">{{ this.$t("message.modals.orderResetModal.p1") }}</p>
        </section>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "OrderResetModal",
  components: {
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    closeModal() {
      this.active = false;
    },
    showModal() {
      this.active = true;
    },
  },
}
</script>

<style scoped lang="scss">

.button.is-secondary.is-inverted:hover {
    background-color: initial;
}

.is-layout-fixed {
    position: fixed !important;
}

.modal {
  z-index: 100;
  .buttons {
    width: 100%;
  }
  .modal-card {
    width: 50%;
    @media (max-width: 768px) {
      min-width: 0px !important;
      width: 90% !important;
    }
  }
}
.modal-card-body {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  p {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
}
.modal-card-head {
  padding: 0px;
  .button {
    color: #D9C731;
  }
}
</style>