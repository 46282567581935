<template>
  <div class="modal" :class="{'is-active': value, 'not-active': vanish}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head is-flex is-justify-content-flex-end">
        <button class="delete" aria-label="close" @click="$emit('close')"></button>
      </header>
      <section class="modal-card-body is-flex is-align-items-flex-start flex-column">
        <slot></slot>
      </section>
      <footer class="modal-card-foot">
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props:  ['value', 'vanish']
}
</script>

<style scoped lang="scss">
.modal {
  padding: 10px;

  &.not-active {
    display: none;
  }
}
</style>