export default {
  pages: {
    landing: {
      primaryText: "Welcome to the online store for customizable products!",
      blueBoxText: "Recyclable and biodegradable, environmentally friendly cardboard cups with your own design. Entirely domestic production.",
      imageOneText: "Recyclable and biodegradable, recyclable, compostable",
      imageTwoText: "100% domestic, responsible production",
      imageThreeText: "Order quantities as needed - no surplus",
      kuppiHeading: "Cup with your design",
      kuppiText: "You can design the illustration to be printed on the cup using our app! Background images, text, and colours are freely selectable. You can also add your own logo. With the 3D preview, you can see what the printed cup will look like.",
      kuppiTextTwo: "Voit käyttää myös PMpro Suunnittelupalvelua, lähetä vain aineistot meille niin suunnittelemme kuppisi.",
      kuppiButtonText: "Try it out!",
      lidsHeading: "Kartonkikannet",
      lidsText: "Meiltä saat myös kuppeihin sopivat kartonkikannet, kansia löytyy 240 ml ja suuremmille kupeille. Kannet tehdään Suomessa kotimaisesta vesidispersiopäällystetystä kartongista ja niihin ei lisätä muovia.",
      lidsButtonText: "Tilaa kannet",
      tuotteetHeading: "Our products",
      tuotteetLineOne: "- Cups come in four sizes",
      tuotteetLineTwo: "- Quantities as needed, starting from 100 pieces",
      tuotteetLineThree: "- Delivery time is approximately 2 - 3 weeks",
      tuotteetLineFour: "- Our production lines are certified for quality, hygiene, and food safety",
      tuotteetButtonText: "Order yours",
      tilaaText: "Subscribe to our newsletter",
      pysyText: "Stay up to date on packaging news and new products in our store!",
      tilaaButton: "Subscribe to the newsletter",
      kuppiOmallaHeading: "More packaging options at Pyroll Shop",
      kuppiOmallaText: "We will be adding more environmentally friendly cardboard packaging with customizable prints to our product range in the future. For now, environmentally friendly cardboard packaging is available from other companies in our group with standard prints.",
      kuppiOmallaLastLineText: "Check it out!",
      kuppiOmallaButtonText: "Pyrollkauppa.fi",      
      otsikkoTitle: "A new era for cardboard containers",
      otsikkoHeadingOne: "Stand out from the crowd",
      otsikkoTextOne: "Companies! The Takeaway container opens up new marketing possibilities. Don't settle for the ordinary - do something that reflects you instead, or delight your customers with personalized cups. A cup doesn't have to be just a cup; it can be a powerful part of your marketing. With takeaway containers, you can make marketing initiatives, quick-cycle campaigns, regionally targeted and tailored events, and other fresh solutions.",
      otsikkoHeadingTwo: "Be creative",
      otsikkoTextTwo: "We'll print your cups with any text or image you choose, including your choice of background colours or images. We also offer ready-made illustrations for your convenience. Any images or text you choose can be freely positioned as you see fit.",
      otsikkoHeadingThree: "Environment and responsibility",
      otsikkoTextThree: "Our cups are made from environmentally friendly, recyclable water-dispersion coated cardboard, and can be recycled as cardboard waste. The cardboard used for the cups is manufactured in Finland, and the cup production is entirely Finnish work. Our production lines are certified for quality, hygiene, and food safety.",
      italic1: "The delivery was quick, the cups just arrived. They look good, thank you! 😊",
      italic1_1: "Read more customer feedback",
      italic2: "How the are cups made?",
      italic2_1: "ake a peek at our factory.",
      italic2_2: "Watch the video",
      italic3: "You can read tips for designing cups here",
      italic3_1: "Design Tip",
      emailErrorText: "Tarkasta sähköpostiosoite",
    },
    shopListPage: {
      headingText: "1/4 Choose product",
      description: "Begin by choosing the type of cup. You can add additional items to your order from the top menu.",
      buttonText: "Choose",
      lidsButtonText: "Lisää ostoskoriin",
      hinnatText: "Prices starting from",
      lidsHeading: "Kannet",
      lidsAdditionalText: "kuppitilauksen yhteydessä"
    },
    editorPage: {
      title: "3/4 Customize product",
      subtitle: "Use the 3D preview to support your design work, there you can see the cup as the finished printed result will be. We will check all the design works before printing and if necessary we will contact you.",
      goCartButtonText: "Add to cart",
      addProductButtonText: "Lisää uusi tuote",
      costPrepText: "á",
      taxText: "ALV",
    },
    cartPage: {
      title: "4/4 Cart",
      deleteProductModalText: "Are you sure you want to remove this product from the shopping cart?",
      voucherButtonText: "Activate",
      goProductListButtonText: "Add a new product",
      costPrepText: "pcs",
      eachPieceText: "pcs",
      radioTextOne: "Private customer",
      radioTextTwo: "Company/organization",
      contactHeadingOne: "Delivery address",
      checkboxBillText: "The billing address is different from the contact information",
      contactRequiredFieldText: "This field is mandatory",
      ytunnusText: "A business ID is required if paying by invoice",
      submitButtonText: "Checkout",
      recvNamePlaceholder: "Billing contact person",
      salesTermsText: "Sales and delivery terms",
      salesTermsClickText: "click here",
      salesTermsErrorText: "sales and delivery terms must be accepted to continue.",
      marketingText: "Yes you can send me marketing messages",
      suunniText: "Suunnittelupalvelu",
      taxText: "ALV",      
    },
    paymentPage: {
      title: "Confirm order",
      subtitle: "Order contents",
      eachPrepText: "pcs",
      eachPieceText: "pcs",
      contactHeadingOne: "Delivery address",
      contactHeadingTwo: "Billing address",
      contactHeadingThree: "Customer information",
      goBackButtonText: "Back",
      payByCardButtonText: "Pay with credit card",
      payByBillButtonText: "Pay by invoice",
      suunniText: "Suunnittelupalvelu",
      lahetammeText_1: "Lähetämme ensisijaisesti sähköisen laskun Y-tunnuksen mukaiseen sähköiseen laskutusosoitteeseen.",
      lahetammeText_2: "Mikäli sinulla ei ole sähköistä laskutusosoitetta lähetämme laskun tilaajan sähköpostiin. Emme lähetä paperilaskuja.",      
    },
    thanksPage: {
      lineOne: "Thank you for your order! We have received your payment.",
      lineTwo: "Thank you for your order!",
      lineThree: "Payment canceled",
      lineFour: "Your order has been received and is waiting for confirmation.",
      lineFifth: "An order summary has been sent to the address",
      lineSixth: "If you cannot see your order confirmation email in your inbox, please check the spam folder.",
      goHomepageButtonText: "Back to homepage"
    },
    termsPage: {
      title: "Terms of Delivery",
      h1: "General information regarding the online store",
      p1_1: "PackageMedia Oy (Business ID: 0159902-7) sells products through the PMPro online store to companies and adult individuals. The customer agrees to the current terms of delivery with each order. By accepting the order and delivery terms, the customer agrees that the customer may be sent an email or called for matters related to the order.",
      p1_2_1: "All orders are confirmed by email. To receive an order confirmation, you must provide your email address when placing your order. Contact our customer service at",
      p1_2_link: " pmpro{'@'}packagemedia.fi",
      p1_2_2: " if you do not receive an order confirmation shortly after placing your order. You should verify the correctness of the order confirmation, as the products will be delivered according to the order confirmation. It is advisable to keep the order confirmation message for possible later use. All customer information is treated confidentially.",
      h2: "Value-added tax",
      p2_1: "A value-added tax of 25.5% is added to the products of the online store. Delivery costs are included in the product price",
      h3: "Delivery methods",
      p3_1: "We deliver products either as Posti Express parcels or in larger quantities as Posti freight. The delivery costs are included in the product price.",
      h4: "Payment methods",
      p4_1: "The payment method for the PMPro online store is payment by card at the time of ordering or invoice for business customers. The payment term is 14 days net. The late payment interest rate is 7% + the reference rate of the Bank of Finland. Companies must provide their business ID for invoicing. The service provider has the right to check the user's credit information before delivering the order. All information is treated confidentially.",
      h5: "Delivery times",
      p5_1: "Individually printed orders from the online store are delivered within 2 - 3 weeks from the date of order. Stock products are delivered within 2 - 3 days of the order. If a product is temporarily out of stock, we will contact the customer to agree on a new delivery time. If the delivery time changes, the customer has the right to cancel the order.",
      p5_2: "We reserve the right to cancel the sale if the product is completely or for a longer period out of stock, the price has been stated obviously incorrectly, or due to some other force majeure. In such cases, the system will send the customer an order confirmation even if the product is not available. PackageMedia's customer service will contact the customer as soon as possible, allowing the customer to cancel the order or to offer a replacement product if available. PackageMedia is not responsible for delays related to the transport companies it uses or third-party transports or for any indirect damages that may be incurred by the customer.",
      h6: "Complaints and other problems",
      p6_1: "In case of any complaints or other problems, please contact us immediately at pmpro@packagemedia.fi or by phone at 05 7472200. Our customer service is open on weekdays from 8 am to 4 pm. A notice of an incorrect delivery or a faulty product must be made within 14 days of receiving the delivery.",
      h7: "Exchange and return",
      p7_1_1: "Private customers have the right according to the Consumer Protection Act to return their online store orders within 14 days of receiving the delivery without a special reason. However, this does not apply to individually printed orders. Always contact our customer service before returning a delivery by emailing us at",
      p7_1_link: " pmpro{'@'}packagemedia.fi ",
      p7_1_2: 'or by phone at 05 7472200. The delivery to be returned must be clearly labelled with the text "return" and the order code.',
      p7_2: "The products must be returned unused, intact, in saleable condition, and in their original packaging. The customer is responsible for the direct costs of returning the delivery. We do not accept advance shipments. After arrival, the return will be checked, and when the return is accepted, the payment related to the return will be made to the account. We may withhold the return of payments until we have received the delivery back or until you have shown that you have sent the delivery back. We do not accept returns more than 14 days after receiving the delivery. If you want to exchange the product you ordered for another one, please make a new order.",
      h8: "Other",
      p8_1: "We reserve the right to change our delivery terms. The customer must familiarize themselves with the current delivery terms before placing an order."
    },
    faqPage: {
      title: "FAQ - Frequently asked questions",
      headingOne: "How can used cups be recycled?",
      textOne: "The cups are recycled with cardboard waste.",
      headingTwo: "Are the cups suitable for both cold and hot drinks?",
      textTwo: "Yes, they are.",
      headingThree: "Do the cups withstand alcoholic beverages?",
      textThree: "The cups withstand mild alcoholic beverages up to about 10% vol. for a limited time.",
      headingFour: "What is the delivery time?",
      textFour: "Products with custom prints are delivered within approximately 10-15 business days.",
      headingFive: "Where are the products manufactured?",
      textFive: "Cups and their raw materials are manufactured entirely in Finland.",
      headingSix: "I don't know how to design a cup, can you help?",
      textSix: "Yes, please contact our customer service, and our professionals will design a cup according to your preferences.",
      headingSeven: "Are the cups safe to use?",
      textSeven: "Yes, all materials used in the cups are food-safe, and strict hygiene guidelines are followed in cup production. The production facility for the cups has an ISO 22000 food safety certificate and audited quality systems.",
      headingEight: "Do the cups contain plastic?",
      textEight: "There is no separate layer of plastic added to the cup cardboard, the cups' liquid resistance is achieved with a water dispersion coating.",
      headingNine: "Do the cups need to have the turtle logo printed on them?",
      textNine: "Even though the cups do not have an added plastic layer, the water dispersion coating may contain polymer substances considered as plastic, and according to the latest EU interpretation dated 7.9.2022, the turtle logo must be printed on them.",
      headingTen: "How much does shipping the cups cost?",
      textTen: "As the cups are delivered free of charge in Finland, the price you see in the online store is the final price.",
      headingEleven: "How can I pay?",
      textEleven: "Individuals can pay by bank or credit card. Companies can also choose to pay by invoice, in which case supplying us with their business ID is mandatory. For invoiced customers, we may check credit information.",
      headingTwelve: "How do I add the turtle logo to the printing materials?",
      textTwelve: "Don't worry, our 3D design software automatically adds a correctly sized version of the logo to the bottom of the cup. It is visible both in the preview and design template.",
      headingThirteen: "Why are PMPron cups easier to recycle and a more responsible choice?",
      textThirteen: "All cardboard cups need some kind of coating to ensure their seamability and, on the other hand, liquid tightness during cup production. Coating options include plastic (PE = polyethene) and bioplastic (PLA = polylactic acid), which is usually sugarcane-based plastic. The water-based dispersion we use does not form a separate plastic layer on the cup and it is biodegradable and recyclable, being the least environmentally burdensome of these options. Also, as our cups are produced entirely in Finland, the burden on nature is further diminished, as materials are not transported long distances.",
      footer: "If you have any questions, please don't hesitate to contact us",
      footerLink: "pmpro@packagemedia.fi",
    },
    pricingPage: {
      title: "Prices",
      p1: "The price of the service depends on the product you choose and the quantity of the order. When you buy more, you get the product at a cheaper price. NOTE! You can combine the printing of several cups of the same size in the same order, then the price is based on the total number of cups. So you can order a small number of cups with several different printings without  the price increasing.  You can see the up-to-date price list for each product on this page.",
      p2: "The final price of the order is made at the checkout.",
      eachPieceText1: "pcs",
      eachPieceText2: "à",
      suunniText: "Suunnittelupalvelu",
      lids1: "Erikseen tilattuna",
      lids2: "Kuppitilauksen yhteydessä"
    },
    aboutPage: {
      title1: "Who are we?",
      p1: "PMPro is PackgageMedia's online store, which offers individually printed cardboard cups and in the future, other take-away and marketing products. PackageMedia is part of the Pyroll Pakkaukset Group, which is Finland's largest producer of consumer packaging. It is almost impossible to return from a shopping trip without a package made by Pyroll in your hand. Pyroll is a 100% domestic operator owned by the Finnish MB Rahastot.",
      p2: "PackageMedia is Pyroll's digital packaging factory and our passion is to combine products with digital services and offer personalized printing for even small orders, we can do it. Here in the PMPro online store, we have developed a unique design machine that allows you to design your cups and other products directly onto the screen of your own device and watch in movable 3D -  a picture of what, for example, your cup will look like.  If you want you can also use our design service, or download the pre-designed cup material. Everything will work.",
      p3: "Our factory is in Inkeroinen, Kouvola. the entire production chain is under one roof. We have all the certificates required for the production of food packaging and we also ensure the safety of our production with our own round the clock tests.",
      title2: "What our customers think",
      italic1: "It has been an absolute excellent service from you so far, I will warmly recommend you to my colleagues.",
      italic2: "The delivery was fast, the cups have just arrived. They look good, thank you! 😊",
      italic3_p1: '"Kookoo" has been working for several years to ensure that all the events will be carried out with the greatest care  and have as little burden on the environment as possible. We want to make our own contribution to the climate talks and we believe that our supporters will appreciate it. This includes serving containers used at the events, (such as, cups, mugs, etc),  switching to the most responsible alternative.',
      italic3_p2: "Replacing plastic beer mugs with cardboard mugs may seem like a more expensive option at first glance, but in the long run it is an investment that pays for itself many times over by saving nature and improving the club's image.",
      italic3_p3: "PMPro's cardboard mugs and cups are a more environmentally friendly option, they do not overburden the environment in the same way as plastic products and plastic-coated products do. Cardboard mugs and cups are compostable and easily recyclable, and their manufacturing process is generally less polluting. The raw materials for the mugs are domestic cardboard and production is in Finland, here in Kouvola. By buying from Finland, we are doing our part in supporting domestic production and thus demonstrating our responsibility in this field . It is also important that the spectators at games and events know that the dishes are environmentally friendly and recyclable.",
      title3: "Peek into the cup factory",
      title4: "Your contact in PMPro",
      mauri1: "Business Director, Pyroll Kuitupakkausket Oy, PackageMedia",
      mauri2: 'Mauri has piloted the company for about 30 years and his special expertise is combining the digital world and physical packaging, "we want to act responsibly and give the packaging much  more clout than just protecting the product". Mauri is responsible for service sales and general management.',
      hannu1: "Factory Manager/Sales Manager",
      hannu2: 'Hannu has worked with fiber packaging for his entire 30-year working career. Now he is responsible for PackageMedia\'s production and the sale of cups and other products, "we try to fulfill the promises made by Mauri to the customers in the field"',
      tiina: "- Tiina-Riikka Turunen, Sulkavan kunta",
      heini: "- Heini",
      sakari: "- Sakari Välimaa, toimitusjohtaja, KooKoo Hockey Oy",      
    },
    tipsPage: {
      title1: "Tips",
      title2: "Preparing the material with an image processing programme",
      p1: "You can make the print material as a ready-made file the size of a cup base or preferably a few millimeters larger with an image processing programme. The sizes of the area to be printed are:",
      p2: "Use the programme to make a finished image with texts and possible logos and transfer it as a background image to the design template, then it will cover the entire area of the cup.",
      p3: "You can zoom in on the image if needed",
      title3: "Using the Toolbar / Grid",
      p4: "The design programme has a grid that can be used as an aid for positioning elements on the same line or, for example, on exactly opposite sides, such as logos in the picture or in the middle of the cup, such as the turtle logo. The lines of the grid are not visible in the 3D view and are not printed on the cup.",
      title4: "Turtle logo",
      p5: "The turtle logo is movable and can be placed in the most suitable place on the cup. However, according to the guidelines of the SUP directive, it must be of a certain size and has to be  horizontal.",
      title5: "Other tips for planning",
      p6: "A white, unprinted area about 6 mm high remains in the lower part of the cups, it is needed to ensure the durability of the seam.",
      p7: "If the cup has a uniform background colour, there may be a 1-2 mm step/gap at the seam due to the acceptable tolerances of the cup formation, and the same may happen to patterns that continue over the seam. In other words; you should make the patterns so that they do not continue over the side seam as a wallpaper pattern, or note that when aligning there may be a slight discrepancy.",
      p8: "The printing of the cups is digital and the printing quality of the images is of a photographic quality. However, the cardboard used for the cups has a slightly yellowish surface and can affect the quality of the images. If you process the images, you should make them a little stronger than normal and anyway it is better to use bright colours, they reproduce best.",
      title6: "Print material delivery, PMS colours",
      p9: "You can also deliver the print material to us as a ready-made file, e.g. in PDF format. We print the cups on a 7-colour digital printing machine with CMYK colours + 3 additional colours, which allows us to use the EPG colour space, with which we can reproduce about 95% of all PMS colours. The cardboard for the cups is however, a little yellowish, which can affect the repetition of colours, for critical colours you should send us exact information about the PMS colour you are looking for, then we will compare it with the colour chart and find the right colours."
    },
    designMethodPage: {
      h3_1: "2/4 Valitse toteutustapa",
      p_1: "Voit suunnitella kupin ulkoasun itse seuraavassa vaiheessa, tai lähettää aineistot meille, jolloin ammattilaisemme suunnittelevat kupin puolestasi. Suunnittelupalvelun hinta on 60,00 € + alv.",
      p_2: "Valittu tuote:",
      p_3: "Suunnittele itse: käytä tätä vaihtoehtoa, kun haluat testailla ja kokeilla erilaisia vaihtoehtoja",
      p_4: "Käytä PMpro Suunnittelupalvelua, kun luotat mieluummin ammattilaisen silmään.",
      button_1: "Suunnittelen kupin itse",
      button_2: "Suunnittelupalvelu",
    },
    requestOrderPage: {
      lahetaText: "3/4 Lähetä aineistot",
      taytaText: "Täytä alle tarvittavat tiedot. Teemme sinulle kupista koevedoksen, jonka saat sähköpostiisi 2 - 3 päivän kuluessa. Jos sinulla on valmiiksi tehty design, voit lähettää sen esim. pdf-tiedostona kohdassa Tausta. Kun aineiston koonti on valmis, siirry ostoskoriin täyttämään tilaustiedot.",
      valittuText: "Valittu tuote",
      tilausText: "Tilausmäärä",
      taustaText: "Tausta",
      sallitutText: "Sallitut tiedostomuodot .jpg, .png, .pdf",
      kuvaText: "Kuva",
      valitseText_1: "Valitse tiedosto",
      poistaText: "Poista",
      variText: "Väri",
      valitseText_2: "Valitse taustaväri",
      eitaustaaText: "Ei taustaa",
      logoText: "Logo",
      tekstiText: "Teksti",
      eitekstiaText: "Ei tekstiä",
      ohjeetText: "Ohjeet",
      jatkaText: "Jatka ostoskoriin"
    }     
  },
  components: {
    addProduct: {
      modalText: "SUP DIRECTIVE REQUIREMENTS\nThe cardboard used in our cups is domestically produced, ecological cardboard and does not have a plastic coating.\nThe liquid resistance of the cups is achieved through water dispersion coating. However, the EU's SUP directive defines water dispersion coating as plastic because it may contain polymer substances similar to plastic, and according to the latest interpretation of the directive, the turtle logo below must be printed on our cups.\nHowever, our biodegradable cups are a better choice for the environment than those made from plastic-coated materials, and their recycling is easy.\nRemember to recycle the cups responsibly!"
    },
    fabricComponent: {
      undoText: "Undo",
      redoText: "Redo",
      clearAllText: "Clear all",
      backgroundText: "Background",
      textText: "Text",
      logoText: "Image/Logo",
      shapesText: "Shapes",
      addNewText: "Add new",
      addCircle: "Add circle",
      addRectangle: "Add rectangle",
      colorText: "Color",
      fontText: "Font",
      selectImageText: "Choose image",
      removeText: "Delete",
      loitonnaText: "Zoom in/out",
      vaihdaText: "Choose/change",
      kierraText: "Rotate",
      kohdistaText: "Align",
      deleteBackgroundPicture: "Delete",
      saveText: "Save",
      cancelText: "Cancel",
      selectColor: "Choose color",
      cancelColor: "Cancel color",
      grid: "Grid",
      lataaOma: "Lataa oma kuvasi tai valitse valmiista vaihtoehdoista",
      valitseTiedosto: "Valitse tiedosto",
      d3Esikatse: "3D Esikatselu"      
    },
    preview3d: {
      kierra: "Rotate by dragging",
    },
    navbar: {
      optionTuotteet: "Products",
      optionVinkk: "Tips for packaging design",
      optionUsein: "Frequently asked questions",
      optionTilaus: "Order and delivery information",
      optionPmpro: "PMpro and contact information",
      optionsHinnasto: "Hinnasto",
      optionOta: "Ota yhteyttä",
    },
    footer: {
      power: "Powered by",
      tieto: "Privacy policy"
    },
    colorPickerFooter: {
      p1: "Nykyinen valinta:"
    }    
  },
  modals: {
    addProductModal: {
      buttonText: "Add to order"
    },
    cartModal: {
      totalText: "X items in total):",
      resetButtonText: "Place a new order",
      goCartButtonText: "View cart"
    },
    contactModal: {
      headingText: "Contact",
    },
    cookieNotification: {
      textOne: "Cookies improve your user experience and help us develop our service.",
      textTwo: "We use both our own and third-party cookies. Learn more about our use of",
      textTwoLink: "cookies.",
      textThree: "You can accept our cookies or change your cookie settings to determine how we use the information collected.",
      declineButton: "VOnly necessary cookies",
      acceptButton: "Accept all cookies",
    },
    notificationModal: {
      p1: "SUP DIRECTIVE REQUIREMENTS",
      p2: "The cardboard we use in our cups is domestically produced, ecological cupboard, to which no separate plastic layer is added. The liquid tightness of the cups is achieved with a water dispersion coating.",
      p3: "However, the EU's SUP Directive defines water dispersion coating as plastic because it may contain plastic-like polymers, and according to the latest interpretation of the directive, the turtle logo below must be printed on our cups.",
      p4: "That said, our biodegradable cups are more environmentally friendly than those made from plastic-coated materials, and their recycling is easy.",
      p5: "Remember to recycle cups responsibly!",
    },
    privacyPolicyModal: {
      title: "Privacy policy",
      h1: "1. Register information",
      p1_1: "Personal Data Act (523/99) § 10",
      p1_2: "Date of preparation  8.8.2022",
      h11: "1.1. Registrar",
      h12: "1.2. Person responsible for register matters",
      h13: "1.3. Name of register",
      p13_1: "PMPro online store user register",
      h14: "1.4. Purpose of processing personal data (purpose of the register)",
      p14_1: "Personal data stored in the PMPro online store user register is used for managing customer relationships, handling inquiries, marketing purposes, and other purposes related to the online service.",
      h15: "1.5. Register contents",
      p15_1: "The register collects basic information about the registered individuals, such as:",
      p15_2: "- name",
      p15_3: "- address",
      p15_4: "- phone number",
      p15_5: "- email",
      p15_6: "- personal identification number for private individuals and business ID for invoicing companies",
      h16: "1.6. Regular sources of information",
      p16_1: "The registrar records the user information provided by the users themselves during their website usage.",
      h17: "1.7. Regular disclosures of data and transfer of data outside the EU or the European Economic Area",
      p17_1: "There are no regular disclosures of data to third parties. There are no transfers of data outside the EU or EEA.",
      h18: "1.8. Principles of register protection",
      p18_1: "The registered user data is stored in the registrar's system. Access to the system requires entering a username and password. The system is also protected by firewalls and other technical means. Only certain pre-defined employees of the registrar are authorized to access and use the data contained in the register. The data contained in the register are located in locked and guarded premises.",
      h19: "1.9. Right to prohibition by the registered",
      p19_1: "The registeree has the right to prohibit the registrar from processing their data for direct marketing, distance selling, and other direct marketing, as well as market and opinion research, and for personal registers and genealogy. The prohibition must be made in writing and addressed to the persons responsible for register matters.",
      h110: "1.10. Right of inspection of the registered",
      p110_1: "The registeree has the right to inspect the data concerning themselves stored in the register and to obtain copies of them. The request for inspection must be made in writing and addressed to the person responsible for register matters.",
      h111: "1.11. Correction of information",
      p111_1: "The registrar corrects, removes, or supplements any erroneous, unnecessary, incomplete, or outdated personal data for the purpose of processing, either on their own initiative or at the request of the registered. The registree should contact the person responsible for register matters to correct the information.",
      h2: "2. Use of Cookies",
      p2_1: "PMPro online store uses cookies to enhance user experience.",
      p2_2: "A cookie is a small text file which is temporarily stored on the user's hard drive. Cookies are used on almost all websites, and websites may not function properly without cookies.",
      p2_3: "A cookie contains a randomly generated unique identifier that allows us to identify your device and collect information about the pages and functions you use on the online store.",
      h21: "2.1.  Cookies and statistics",
      p21_1: "We use Google Analytics to collect information about how the online store is used. With this information, we aim to understand what the online store customers want and how we can best provide customers with a good user experience.",
      h22: "2.2. Preventing and deleting cookies",
      p22_1: "You can prevent the use of cookies on your device by changing your browser settings. The location of the settings depends on the browser. However, it is worth noting that if you block the use of cookies, our online store may not function properly."
    },
    termsModal: {
      title: "Toimitusehdot",
      h1: "Yleistä verkkokaupasta",
      p1_1: "Pyroll Kuitupakkaukset Oy (Y-tunnus: 0159902-7) myy PMPro -verkkokaupasta tuotteita yrityksille sekä täysi-ikäisille yksityishenkilöille. Asiakas sitoutuu jokaisen tilauksen teon yhteydessä voimassa oleviin toimitusehtoihin. Hyväksymällä tilaus- ja toimitusehdot asiakas hyväksyy, että asiakkaalle voidaan tarvittaessa lähettää sähköpostia tai soittaa tilaukseen liittyvissä asioissa.",
      p1_2_1: "Kaikki tilaukset vahvistetaan sähköpostiviestillä, Tilausvahvistuksen saaminen edellyttää sähköpostiosoitteen ilmoittamista tilauksen yhteydessä. Ota yhteyttä asiakaspalveluun,",
      p1_2_link: " pmpro{'@'}packagemedia.fi",
      p1_2_2: " mikäli et saa tilausvahvistusta lyhyen ajan kuluessa tilauksesi tekemisestä. Asiakkaan tulee tarkistaa tilausvahvistuksen oikeellisuus, sillä tuotteet toimitetaan tilausvahvistuksen mukaisesti. Tilausvahvistusviesti kannattaa säilyttää mahdollista myöhempää tarvetta varten. Kaikki asiakastiedot käsitellään luottamuksellisesti.",
      h2: "Arvonlisävero",
      p2_1: "Verkkokaupan tuotteisiin lisätään arvonlisävero 25.5%",
      h3:"Toimitustavat",
      p3_1: "Toimitamme tuotteet joko Postin express pakettina tai isommat määrät Postin rahtina. Toimituskulut sisältyvät tuotteen hintaan",
      h4:"Maksutavat",
      p4_1: "PMPro-verkkokaupan maksutapana on maksu kortilla tilauksen yhteydessä tai yritysasiakkaille lasku. Maksuehtona on 14 päivää netto. Viivästyskorko on 7 % + Suomen Pankin viitekorko. Yritysten on ilmoitettava Y-tunnus laskutusta varten. Palveluntarjoajalla on oikeus tarkistaa käyttäjän luottotiedot ennen tilauksen toimittamista. Kaikki tiedot käsitellään luottamuksellisesti.",
      h5:"Toimitusajat",
      p5_1: "Verkkokaupan yksilöllisesti painetut tilaukset toimitetaan 2 – 3 viikon kuluessa tilauksen saapumisesta. Varastotuotteet toimitetaan 2 – 3 päivän kuluessa tilauksesta. Mikäli tuote on hetkellisesti loppu varastostamme, otamme tilaajaan yhteyttä uuden toimitusajan sopimiseksi. Toimitusajan muuttuessa asiakkaalla on oikeus peruuttaa tilaus.",
      p5_2: "Pidätämme oikeuden kaupan perumiseen, mikäli tuote on kokonaan tai pidemmän aikaa loppuunmyyty, hinta on ilmoitettu ilmeisen virheellisesti tai jonkun muun ylivoimaisen esteen johdosta. Tällaisissa tapauksissa järjestelmä lähettää asiakkaalle tilausvahvistuksen, vaikka tuotetta ei olekaan saatavilla. PackageMedian asiakaspalvelu ottaa asiakkaaseen yhteyttä mahdollisimman nopeasti antaen asiakkaalle mahdollisuuden peruuttaa tilaus tai tarjoten korvaavaa tuotetta, mikäli sellainen on saatavilla. PackageMedia ei vastaa käyttämiensä kuljetusyritysten taikka kolmannen osapuolen kuljetuksiin liittyvistä tai muista viiveistä eikä asiakkaalle mahdollisesti aiheutuvista välillisistä vahingoista.",
      h6:"Reklamaatiot ja muut ongelmatilanteet",
      p6_1: "Mahdollisissa reklamaatioasioissa tai muissa ongelmatilanteissa pyydämme ottamaan viipymättä yhteyttä pmpro{'@'}packagemedia.fi tai puhelimitse 050 5320575. Asiakaspalvelumme on avoinna arkisin kello 8-16. Ilmoitus virheellisestä toimituksesta tai viallisesta tuotteesta on tehtävä 14 päivän sisällä toimituksen vastaanottamisesta.",
      h7:"Vaihto ja palautus",
      p7_1_1: "Yksityisasiakkailla on kuluttajasuojalain mukainen oikeus palauttaa verkkokaupan tilauksensa 14 vuorokauden kuluessa toimituksen vastaanottamisesta ilman erityistä syytä. Tämä ei kuitenkaan koske yksilöllisesti painettuja tilauksia. Ota aina yhteyttä ennen toimituksen palauttamista asiakaspalveluumme",
      p7_1_link: " pmpro{'@'}packagemedia.fi ",
      p7_1_2: "tai puhelimitse 050 5320575. Palautettavaan toimitukseen on kirjoitettava näkyvästi teksti ”palautus” sekä tilauskoodi.",
      p7_2: "Tuotteet tulee palauttaa käyttämättöminä, ehjinä, myyntikelpoisina ja alkuperäispakkauksissa. Asiakkaan on vastattava itse toimituksen palauttamisesta johtuvista välittömistä kustannuksista. Ennakkolähetyksiä emme lunasta. Palautus siirtyy saapumisen jälkeen tarkistettavaksi ja kun palautus on hyväksytty, maksetaan palautukseen liittyvä suoritus tilille. Voimme pidättyä maksujen palautuksesta, kunnes olemme saaneet toimituksen takaisin tai kunnes olette osoittanut lähettäneenne toimituksen takaisin. Emme vastaanota palautuksia yli 14 vuorokauden kuluttua toimituksen vastaanottamisesta. Mikäli haluat vaihtaa tilaamasi tuotteen toiseen, ole ystävällinen ja tee uusi tilaus.",
      h8:"Muuta",
      p8_1: "Pidätämme oikeuden muuttaa toimitusehtojamme. Asiakkaan on ennen tilaustaan tutustuttava kulloinkin voimassa oleviin toimitusehtoihin."
    },
    editorHelpModal: {
      h2_1: "Hei, tarvitsetko apua kupin suunnittelussa?",
      p_1: "Täältä löydät vastauksia ja vinkkejä:",
      a_1: "Usein kysyttyä",
      a_2: "Vinkkejä suunnitteluun",
      p_2: "Suunnittelupalvelu",
      p_3: "Voit myös antaa PMpron ammattilaisten suunnitella kuppisi. Lähetät vain aineistot meille.",
      span_1: "Lähetä aineistot",
      p_4: "Asiakaspalvelumme auttaa kaikissa kysymyksissä:",
    },
    newsletterThanksModal: {
      h2_1: "Kiitos!",
      p_1: "Lisäämme sinut uutiskirjeen tilaajaksi.",
    },
    orderResetModal: {
      p1: "Ostoskorisi tyhjentyi, koska kansien minimitilausmäärä ilman kuppeja on 1000 kpl"
    }
  }
}
