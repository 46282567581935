<template>
  <div class="popover-content">

    <div v-for="(product, index) in not_draft_products" :key="product.id" class="columns is-size-5 is-vcentered is-mobile" v-bind:class="{ 'grey-box': index % 2 == 0 }">
      <div class="column is-4 has-text-centered">
        <figure class="image is-2by1 is-thumbnail" v-if="product.thumbnail_url != ''">
          <img  :src="product.thumbnail_url" />
        </figure>
        <figure class="image is-96x96 is-thumbnail" v-else-if="product.product.thumbnail != ''" style="margin-left: auto; margin-right: auto;">
          <img :src="product.product.thumbnail" />
        </figure>
      </div>
      <div class="column is-4 has-text-centered is-relative is-hidden-mobile" style="padding-top: 30px;">
        <div class="text-wrapper is-inline-block ml-3">
          <span class="position">x {{product.amount}}</span>
        </div>
      </div>
      <div class="column is-4 has-text-centered is-relative is-hidden-mobile" style="padding-top: 30px;">
        <div class="text-wrapper is-inline-block ml-3">
          <span class="position">{{ getItemLineTotalPrice(product) }} €</span>
        </div>
      </div>
      <div class="column is-4 has-text-centered is-hidden-tablet" style="padding-top: 30px;">
        <div class="text-wrapper">
          <span class="position">x {{product.amount}} = {{ getItemLineTotalPrice(product) }} €</span>
        </div>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-12 has-text-right">
        <p>{{ this.$t("message.modals.cartModal.totalText") }} <b>{{ getTotalPrice }} €</b></p>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-6 has-text-left">
        <button
          :disabled="getSavedItems().length + getNonDraftOrderForms().length == 0"
          class="upload-button button pm-button-navbar"
          @click="resetCart()"
        >
          <span>{{ this.$t("message.modals.cartModal.resetButtonText") }}</span>
      </button>
      </div>
      <div class="column is-6 has-text-right">
        <button
          :disabled="getSavedItems().length + getNonDraftOrderForms().length == 0"
          class="upload-button button pm-button-navbar"
          @click="goToCart()"
        >
          <span>{{ this.$t("message.modals.cartModal.goCartButtonText") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import asModalDialogMixin from "@/helpers/modalMixing"
import cartMixin from "@/mixins/cartMixin";

export default {
  name: 'cartModal',
  mixins: [
    asModalDialogMixin,
    cartMixin
  ],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  }
}
</script>

<style lang="scss" scoped>
.is-layout-fixed{
  position: fixed !important;
}

.mobile-modal {
  .buttons {
    right: 0;
    top: 0;
    position: absolute;
  }
}

.popover-content {
  z-index: 10;
  position: absolute;
  background-color: white;
  width: 525px;
  right: 200px;
  padding: 20px 20px 20px 20px;
  border: 1px solid black;
  top: 101px;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  @media (max-width: 768px) {
    width: 100%;
    right: 0;
  }
  p {
    font-size: 20px;
  }
}

.box-text {
  white-space: pre-wrap;
}

.box-text-wrapper {
  text-align: center;
}

.cart-logo-small {
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  &.portrait {
    right: 5px;
    bottom: 5px;
    width: 15px;
    height: 15px;
  }
  &.landscape {
    right: 8px;
    bottom: 8px;
    @media (max-width: 768px) {
      right: 5px;
      bottom: 5px;
    }
  }
}

span.position {
  @media (max-width: 768px) {
    font-size: 12px;
  }
}
</style>
