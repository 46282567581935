<template>
  <section class="hero is-fullheight faq-page">
    <PmNavbar />
    <div class="hero-body">
      <div class="container">
        <div class="columns light-bg is-multiline p-5">
          <div class="column is-12">
            <h2 class="mb-3 font-secondary font-28">{{ this.$t("message.pages.faqPage.title") }}</h2>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingOne") }}
            </h3>
            <p class="font-18">{{ this.$t("message.pages.faqPage.textOne") }}</p>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingTwo") }}
            </h3>
            <p class="font-18">{{ this.$t("message.pages.faqPage.textTwo") }}</p>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingThree") }}
            </h3>
            <p class="font-18">
              {{ this.$t("message.pages.faqPage.textThree") }}
            </p>

            <h3 class="mt-5 font-secondary font-20">{{ this.$t("message.pages.faqPage.headingFour") }}</h3>
            <p class="font-18">
              {{ this.$t("message.pages.faqPage.textFour") }}
            </p>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingFive") }}
            </h3>
            <p class="font-18">{{ this.$t("message.pages.faqPage.textFive") }}</p>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingSix") }}
            </h3>
            <p class="font-18">
              {{ this.$t("message.pages.faqPage.textSix") }}
            </p>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingSeven") }}
            </h3>
            <p class="font-18">
              {{ this.$t("message.pages.faqPage.textSeven") }}
            </p>

            <h3 class="mt-5 font-secondary font-20">{{ this.$t("message.pages.faqPage.headingEight") }}</h3>
            <p class="font-18">
              {{ this.$t("message.pages.faqPage.textEight") }}
            </p>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingNine") }}
            </h3>
            <p class="font-18">
              {{ this.$t("message.pages.faqPage.textNine") }}
            </p>

            <h3 class="mt-5 font-secondary font-20">{{ this.$t("message.pages.faqPage.headingTen") }}</h3>
            <p class="font-18">
              {{ this.$t("message.pages.faqPage.textTen") }}
            </p>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingEleven") }}
            </h3>
            <p class="font-18">
              {{ this.$t("message.pages.faqPage.textEleven") }}
            </p>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingTwelve") }}
            </h3>
            <p class="font-18">
              {{ this.$t("message.pages.faqPage.textTwelve") }}
            </p>

            <h3 class="mt-5 font-secondary font-20">
              {{ this.$t("message.pages.faqPage.headingThirteen") }}
            </h3>
            <p class="mb-5 font-18">
              {{ this.$t("message.pages.faqPage.textThirteen") }}
            </p>                        

            <p class="mt-5 font-18">
              {{ this.$t("message.pages.faqPage.footer") }}
              <a href="mailto:pmpro@packagemedia.fi">pmpro@packagemedia.fi</a>
            </p>
          </div>
        </div>
        <div class="columns light-bg p-5" style="margin-top: 15px;">
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-start">
            <div class="img-container is-flex is-justify-content-center is-align-items-center">
              <img class="cstm-icon" src="../assets/img/recycle-solid.svg" />
            </div>
            <p class="has-text-centered font-18 pb-4">
              {{ this.$t("message.pages.landing.imageOneText") }}
            </p>
          </div>
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-end">
            <div class="img-container is-flex is-justify-content-center is-align-items-center">
              <img class="cstm-icon" src="../assets/img/map-marker-alt-solid.svg" />
            </div>
            <p class="has-text-centered font-18 pb-4">
              {{ this.$t("message.pages.landing.imageTwoText") }}
            </p>
          </div>
          <div class="column is-4 is-flex is-flex-direction-column is-justify-content-start">
            <div class="img-container is-flex is-justify-content-center is-align-items-center">
              <img class="cstm-icon" src="../assets/img/balance-scale-solid.svg" />
            </div>
            <p class="has-text-centered font-18 pb-4">
              {{ this.$t("message.pages.landing.imageThreeText") }}
            </p>
          </div>                    
        </div>           
      </div>
    </div>
    <PmFooter />
  </section>  
</template>

<script>
import PmNavbar from "../components/PmNavbar";
import PmFooter from "../components/PmFooter";

export default {
  name: "FaqPage",
  components: {
    PmNavbar,
    PmFooter,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  computed: {},
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.faq-page {
  .light-bg {
    background-color: $pm-light;
  }
  .img-container {
    height: 150px;
    flex-grow: 1;
  }
  .cstm-icon {
    height: 80px;
  }  
}
</style>
