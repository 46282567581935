<template>
  <teleport to="#modals">
    <div class="modal" :class="{ 'is-active': active }">
      <div class="modal-background" @click="this.closeModal()"></div>
      <div class="modal-content has-background-white p-4 container">
        <img
          src="../assets/icons/close.svg"
          class="is-pulled-right close-button"
          @click="this.closeModal()"
        />
        <div class="is-clearfix"></div>

        <div class="columns">
          <div class="column is-12">
            <h2 class="mb-3">{{ this.$t("message.modals.contactModal.headingText") }}</h2>
            <p>Pyroll Kuitupakkaukset Oy</p>
            <p>Valtatie 12</p>
            <p>46900 Inkeroinen</p>
            <p class="mt-5">pmpro@packagemedia.fi</p>
            <p>puh. 050 5320575</p>
            <p class="mt-5">Y-tunnus: 0159902-7</p>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "ContactModal",
  components: {},
  data() {
    return {
      active: false,
    };
  },
  methods: {
    closeModal() {
      this.active = false;
    },
    showModal() {
      this.active = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.modal {
  .modal-content {
    max-height: 400px;
    max-width: 350px !important;
  }
  .close-button {
    cursor: pointer;
  }

  h2 {
    font-size: 30px;
    color: $font-color-primary;
    font-family: $font-secondary;
  }

  h3 {
    color: $font-color-primary;
    font-size: 20px;
    font-family: $font-secondary;
  }
  
  p {
    font-family: $font-primary;
    color: $font-color-primary;
    font-size: 20px;
  }
}

</style>
