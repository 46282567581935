<template>
  <teleport to="#modals">
    <div class="modal" :class="{ 'is-active': active }">
      <div class="modal-background" @click="this.closeModal()"></div>
      <div class="modal-content has-background-white p-4 container">
        <img
          src="../assets/icons/close.svg"
          class="is-pulled-right close-button"
          @click="this.closeModal()"
        />
        <div class="is-clearfix"></div>

        <div class="columns">
          <div class="column is-12">
            <h2 class="mb-3">{{ this.$t("message.modals.privacyPolicyModal.title") }}</h2>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h1") }}</h3>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p1_1") }}</p>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p1_2") }}</p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h11") }}</h3>
            <p>Pyroll Kuitupakkaukset Oy</p>
            <p>Valtatie 12</p>
            <p>46900 Inkeroinen</p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h12") }}</h3>
            <p>Pyroll Kuitupakkaukset Oy</p>
            <p>Y-tunnus 0159902-7</p>
            <p>Valtatie 12</p>
            <p>46900 Inkeroinen, Finland</p>
            <p>050 5320575</p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h13") }}</h3>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p13_1") }}</p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h14") }}</h3>
            <p>
              {{ this.$t("message.modals.privacyPolicyModal.p14_1") }}
            </p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h15") }}</h3>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p15_1") }}</p>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p15_2") }}</p>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p15_3") }}</p>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p15_4") }}</p>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p15_5") }}</p>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p15_6") }}</p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h16") }}</h3>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p16_1") }}</p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h17") }}</h3>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p17_1") }}</p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h18") }}</h3>
            <p>
              {{ this.$t("message.modals.privacyPolicyModal.p18_1") }}
            </p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h19") }}</h3>
            <p>
              {{ this.$t("message.modals.privacyPolicyModal.p19_1") }}
            </p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h110") }}</h3>
            <p>
              {{ this.$t("message.modals.privacyPolicyModal.p110_1") }}
            </p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h111") }}</h3>
            <p>
              {{ this.$t("message.modals.privacyPolicyModal.p111_1") }}
            </p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h2") }}</h3>
            <p>{{ this.$t("message.modals.privacyPolicyModal.p2_1") }}</p>
            <p>
              {{ this.$t("message.modals.privacyPolicyModal.p2_2") }}
            </p>
            <p>
              {{ this.$t("message.modals.privacyPolicyModal.p2_3") }}
            </p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h21") }}</h3>
            <p>
              {{ this.$t("message.modals.privacyPolicyModal.p21_1") }}
            </p>

            <h3 class="mt-5">{{ this.$t("message.modals.privacyPolicyModal.h22") }}</h3>
            <p>
              {{ this.$t("message.modals.privacyPolicyModal.p22_1") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "PrivacyModal",
  components: {},
  data() {
    return {
      active: false,
    };
  },
  methods: {
    closeModal() {
      this.active = false;
    },
    showModal() {
      this.active = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.modal {
  .modal-content {
    
  }
  .close-button {
    cursor: pointer;
  }

  h2 {
    font-size: 30px;
    color: $font-color-primary;
    font-family: $font-secondary;
  }

  h3 {
    color: $font-color-primary;
    font-size: 20px;
    font-family: $font-secondary;
  }
  
  p {
    font-family: $font-primary;
    color: $font-color-primary;
    font-size: 20px;
  }
}

</style>
