<template>
  <div class="popover-content">
    <div class="columns is-mobile mb-0 mt-5" v-for="(product, idx) in $store.getters.getProducts" :key="idx">
      <div class="column is-6 mb-0 pb-0 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
        <figure class="image is-96x96">
          <img :src="product.thumbnail"/>
        </figure>
        <p class="is-hidden-mobile">{{product.name}}</p>
      </div>
      <div class="column is-6 mb-0 pb-0 has-text-right is-flex is-justify-content-center is-align-items-center">
        <button
          class="upload-button button pm-button-navbar"
          @click="$router.push({name: 'DesignMethodPage', params: { productId: product.id }})"
          v-if="product.resource_type == 'Cup'"
        >
          <span>{{ this.$t("message.modals.addProductModal.buttonText") }}</span>
        </button>
        <button
          class="upload-button button pm-button-navbar"
          @click="selectProductEditor(product.id, 'Lid', 'cart')"
          v-else-if="product.resource_type == 'Lid'"
        >
          <span>{{ this.$t("message.modals.addProductModal.buttonText") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import addProductMixin from "@/mixins/addProduct"

export default {
  name: 'addProductModal',
  mixins: [addProductMixin],
}
</script>

<style lang="scss" scoped>
.is-layout-fixed{
  position: fixed !important;
}

.mobile-modal {
  .buttons {
    right: 0;
    top: 0;
    position: absolute;
  }
}

.popover-content {
  z-index: 10;
  position: absolute;
  background-color: white;
  width: 525px;
  right: 411px;
  padding: 20px 20px 20px 20px;
  border: 1px solid black;
  top: 101px;
  @media (max-width: 768px) {
    width: 100%;
    right: 0;
  }
  p {
    font-size: 20px;
    margin-left: 20px;
    float: left;
    margin-top: 15px;
  }
  figure {
    float: left;
  }
  button {
    width: 174px;
    @media (max-width: 768px) {
      margin-top: 25px;
    }
  }
}
</style>
