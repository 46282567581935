import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'
import {waitForStorageToBeReady} from '../utils'
import LandingPage from '../views/LandingPage'
import ShopListPage from '../views/ShopListPage'
import EditorPage from '../views/EditorPage'
import CartPage from '../views/CartPage'
import PaymentPage from '../views/PaymentPage'
import ThankYouPage from '../views/ThankYouPage'
import DesignMethodPage from '../views/DesignMethodPage'
import RequestOrderFormPage from '../views/RequestOrderFormPage'
import AboutUsPage from '../views/AboutUsPage'
import FaqPage from '../views/FaqPage'
import TermsPage from '../views/TermsPage'
import TipsPage from '../views/TipsPage'
import PricingListPage from '../views/PricingListPage'
import { trackRouter } from "vue-gtag-next"

var hash = location.hash

if (hash && hash.indexOf('#!') === 0) {
  location.hash = hash.slice(2)
}

export const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'Landing',
        component: LandingPage,
      },
      {
        path: '/shoplist',
        name: 'ShopList',
        component: ShopListPage,
      },
      {
        path: '/:productId/designmethod',
        name: 'DesignMethodPage',
        component: DesignMethodPage,
      },
      {
        path: '/:orderUuid/:requestUuid/:productId/requestorderform',
        name: 'RequestOrderFormPage',
        component: RequestOrderFormPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/:uuid/:item/:product/editor/',
        name: 'Editor',
        component: EditorPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/:uuid/cart',
        name: 'Cart',
        component: CartPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/:uuid/payment',
        name: 'PaymentPage',
        component: PaymentPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/thanks',
        name: 'thanks',
        component: ThankYouPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUsPage,
      },
      {
        path: '/faq',
        name: 'FaqPage',
        component: FaqPage,
      },
      {
        path: '/terms',
        name: 'TermsPage',
        component: TermsPage,
      },
      {
        path: '/tips',
        name: 'TipsPage',
        component: TipsPage,
      },
      {
        path: '/pricing',
        name: 'PricingListPage',
        component: PricingListPage,
      },              
    ],
    scrollBehavior (to, from, savedPosition) {
      document.getElementById('app').scrollIntoView();
    }
  });

  router.beforeEach(async (to, from, next) => {
    await waitForStorageToBeReady()
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.getToken) {
        next({
          path: '/'
        })
      } else {
        next()
      }
    } else {
      next()
    }
  });

  trackRouter(router);

  export default router