<template>
  <div style="min-height: 500px; height: 80vh; width: 100%" @mousedown="handHidden = true" class="is-relative">
    <div
      class="handWrapper has-text-centered"
      :class="{ 'is-hidden': handHidden }"
      @mousedown="handHidden = true"
      @touchstart="handHidden = true"
    >
      <div class="handCircle" :class="{ 'is-hidden': handHidden }">
        <span class="icon is-large is-primary fixPos">
          <i class="fas fa-2x fa-hand-point-up"></i>
        </span>
      </div>

      <p class="mt-2 is-size-4">
        {{ this.$t("message.components.preview3d.kierra") }}
      </p>
    </div>
    <div ref="3dview"></div>

    <canvas
      :class="{ 'is-hidden': textureHidden }"
      ref="imageCanvas"
      width="1280px"
      height="960px"
      style="border: 1px solid black"
    />
  </div>
</template>

<script>
import { loadImage, Viewer } from '@/3d/utils'

/*
Notes:

This will create one texture 1280 x 960 and copy images provided
in texture property to internal canvas
Texture is made with that canvas as image and placed into #d object of choise.

 */

function rotateAndPaintImage(context, image, angleInRad, positionX, positionY, axisX, axisY, w, h) {
  context.translate(positionX, positionY)
  context.rotate(angleInRad)
  context.drawImage(image, -axisX, -axisY, w, h)
  context.rotate(-angleInRad)
  context.translate(-positionX, -positionY)
}

let TO_RADIANS = Math.PI / 180

export default {
  name: 'ThreeView',
  props: {
    uri: {
      type: String,
      required: true
    },
    gblVariable: '',
    textureWidth: 0,
    textureHeight: 0,
    texture: {
      type: Object,
      required: false,
    },
    options: {
      type: Object,
      default: function () {
        return {
          axes_helper: false,
          debug_gui: false,
        }
      },
    },
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      componentKey: 0,
      viewer: null,
      canvasContext: null,
      canvasTexture: null,
      textureHidden: true,
      handHidden: false,
    }
  },
  mounted() {
    this.viewer = new Viewer(this.$refs['3dview'], this.options)
    if (this.uri !== '') {
      let self = this
      this.initCanvas()
      this.loadScene(this.uri).then(async () => {
        let y_offset = 0
        for (const textureObj of this.texture) {
          if(!('dataUrl' in textureObj)) continue;  // skip if not url
          const im = new Image()
          im.crossOrigin='anonymous'
          let image = await loadImage(textureObj.dataUrl, im)
          if(!('posy' in textureObj)){
            // automatic positioning
            self.updateCanvasImage(image, 0, y_offset, this.textureWidth, textureObj.height, textureObj.rotate)
            y_offset += textureObj.height
          }else{
            // absolute positioning
            self.updateCanvasImage(image, textureObj.posx, textureObj.posy, textureObj.width, textureObj.height, textureObj.rotate)
            y_offset += textureObj.height
          }
        }
        this.viewer.changeMaterialToCanvas(this.gblVariable, this.canvasTexture)
        return
      })
    }
  },

  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    loadScene(uri) {
      return new Promise((resolve, _) => {
        let fileUrl = uri
        let fileMap = new Map()
        let self = this
        this.viewer.load(fileUrl, null, fileMap).then(() => {
          self.viewer.resize()
          //self.viewer.render()
          resolve()
        })
      })
    },

    initCanvas() {
      let canvas = this.$refs.imageCanvas
      canvas.width = this.textureWidth
      canvas.height = this.textureHeight
      this.canvasContext = canvas.getContext('2d') // draw image in canvas starting left-0 , top - 0     context.drawImage(image, 0, 0, width, height );  //  downloadImage(canvas); need to implement};image.src = blobURL;
      this.canvasTexture = canvas
    },
    updateCanvasImage(image, x = 0, y = 0, w = 100, h = 100, angle = 0) {
      //this.canvasContext.clearRect(x, y, w, h)
      if (angle) {
        rotateAndPaintImage(this.canvasContext, image, -angle * TO_RADIANS, x, w - y, 0, 0, h, w)
      } else {
        this.canvasContext.drawImage(image, x, y, w, h)
      }
    }
  },
  computed: {
    imageSizes() {
      return [0, 0, this.textureWidth, this.textureHeight]
    },
  },
}
</script>

<style scoped lang="scss">
.axes {
  width: 100px;
  height: 100px;
  margin: 20px;
  padding: 0px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 10;
  pointer-events: none;
}

.hand-invisible {
  display: none;
}

.handWrapper {
  background-color: rgba(111,111,111,25%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &.is-hidden {
    position: relative;
  }
}

.fixPos {
  position: relative;
  bottom: -20px;
}

.handCircle {
  display: block;
  width: 50px;
  padding: 5px;
  left: 50%;
  top: 50%;
  background-color: #808080bf;
  border-radius: 25px;
  transform: translate(-50%, -50%);
  z-index: 10;
  position: absolute;
  opacity: 0.8;
  //# -webkit-animation: handimation 7s linear infinite;
  animation: handimation 7s linear infinite;
}

@keyframes handimation {
  0%,
  25%,
  50%,
  75%,
  100% {
    visibility: visibile;
    margin-left: 0px;
    margin-top: 0px;
  }
  12.5% {
    margin-left: -70px;
    margin-top: 0px;
  }
  37.5% {
    margin-left: 70px;
    margin-top: 0px;
  }
  62.5% {
    margin-left: 0px;
    margin-top: -70px;
  }
  87.5% {
    margin-left: 0px;
    margin-top: 70px;
  }
}

.handCircle {
  width: 120px;
  line-height: 120px;
  border-radius: 50%;
  text-align: center;
  font-size: 32px;
  #border: 2px solid #666;
}
</style>
