import camelCase from 'lodash/camelCase'
/*
 * createAsyncMutation('GET_INFO_ASYNC') will lead to:
 *
 * GET_INFO_ASYNC = {
 *      BASE: GET_INFO_ASYNC_BASE,
 *      SUCCESS: GET_INFO_ASYNC_SUCCESS,
 *      PENDING: GET_INFO_ASYNC_PENDING,
 *      FAILURE: GET_INFO_ASYNC_FAILURE,
 *      loadingKey: getInfoAsyncPending,
 *      errorCode: getInfoAsyncErrorCode,
 *      stateKey: getInfoAsyncData
 * }
 *
 */
const createAsyncMutation = (type) => ({
  BASE: `${type}`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  PENDING: `${type}_PENDING`,
  loadingKey: camelCase(`${type}_PENDING`),
  statusCode: `${camelCase(type)}StatusCode`,
  stateKey: camelCase(`${type}_DATA`),
  errorKey: camelCase(`${type}_ERRORS`)
})

export const GET_AUTHENTICATE = createAsyncMutation('GET_AUTHENTICATE')
export const GET_PRODUCTS = createAsyncMutation('GET_PRODUCTS')
export const POST_ORDER = createAsyncMutation('POST_ORDER')
export const GET_ORDER = createAsyncMutation('GET_ORDER')
export const POST_ITEM = createAsyncMutation('POST_ITEM')
export const DELETE_ITEM = createAsyncMutation('DELETE_ITEM')
export const UPDATE_ITEM = createAsyncMutation('UPDATE_ITEM')
export const POST_REQUEST_ORDER_FORM = createAsyncMutation('POST_REQUEST_ORDER_FORM')
export const DELETE_REQUEST_ORDER_FORM = createAsyncMutation('DELETE_REQUEST_ORDER_FORM')
export const UPDATE_REQUEST_ORDER_FORM = createAsyncMutation('UPDATE_REQUEST_ORDER_FORM')
export const LIST_DISCOUNT_VOLUMES = createAsyncMutation('LIST_DISCOUNT_VOLUMES')
export const POST_CONTACT = createAsyncMutation('POST_CONTACT')
export const PUT_CONTACT = createAsyncMutation('PUT_CONTACT')
export const PARTIAL_UPDATE_ORDER = createAsyncMutation('PARTIAL_UPDATE_ORDER')
export const IMAGE_BANK = createAsyncMutation('IMAGE_BANK')
export const ADD_VOUCHER = createAsyncMutation('ADD_VOUCHER')
export const POST_NEWSLETTER = createAsyncMutation('POST_NEWSLETTER')
export const GET_SERVICE_FEES = createAsyncMutation('GET_SERVICE_FEES')
