<template>
  <teleport to="#modals">
    <div class="modal is-active is-layout-fixed" v-if="this.$store.getters.getNotificationActive" tabindex="0" ref="modalroot">
      <div class="modal-background" @click="answerNAY"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="buttons is-right">
            <button class="button is-secondary is-inverted" @click="answerNAY">
              <span class="icon is-medium">
                <i class="fas fa-2x fa-window-close"></i>
              </span>
            </button>
          </div>
        </header>
        <section class="modal-card-body">
          <p>{{ this.$t("message.modals.notificationModal.p1") }}</p>
          <p class="mt-3">{{ this.$t("message.modals.notificationModal.p2") }}</p>
          <p class="mt-3">{{ this.$t("message.modals.notificationModal.p3") }}</p>
          <p class="mt-3">{{ this.$t("message.modals.notificationModal.p4") }}</p>
          <p class="mt-3">{{ this.$t("message.modals.notificationModal.p5") }}</p>
          <img class="mt-3" src="../assets/img/turtle.png" width=200 height="auto">
        </section>
      </div>
    </div>
  </teleport>
</template>
<script>
import {mapMutations} from "vuex";

export default {
  name: 'notificationModal',
  methods: {
    ...mapMutations([
      'setNotificationActiveState'
    ]),
    answerNAY(...args) {
      document.documentElement.style.overflow = 'auto'
      this.setNotificationActiveState({'notificationState': false, 'message': ""})
    },
  },
  computed: {
    message() {
      return this.$store.getters.getNotificationMessage
    }
  }
}
</script>
<style scoped lang="scss">

.button.is-secondary.is-inverted:hover {
    background-color: initial;
}

.is-layout-fixed {
    position: fixed !important;
}

.modal {
  z-index: 100;
  .buttons {
    width: 100%;
  }
  .modal-card {
    width: 50%;
    @media (max-width: 768px) {
      min-width: 0px !important;
      width: 90% !important;
    }
  }
}
.modal-card-body {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  p {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
}
.modal-card-head {
  padding: 0px;
  .button {
    color: #D9C731;
  }
}
</style>
