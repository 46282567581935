<template>
  <section class="hero is-fullheight pricinglist-page">
    <PmNavbar />
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-12 mb-4">
            <div class="content">
              <h2 class="font-secondary has-text-centered mt-5 pt-5">{{ this.$t("message.pages.pricingPage.title") }}</h2>
              <p class="has-text-centered">{{ this.$t("message.pages.pricingPage.p1") }}</p>
              <p class="has-text-centered">{{ this.$t("message.pages.pricingPage.p2") }}</p>
            </div>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-6 mb-5" v-for="(product, idx) in $store.getters.getProducts" :key="idx">
            <div class="content">
              <p class="has-text-centered font-secondary mb-0">
                {{product.name}}
              </p>
              <figure class="image is-128x128 m-auto cups-img">
                <img :src="product.thumbnail" />
              </figure>                 
              <p class="has-text-centered font-13">
                {{this.getProductDescription()(product.id)}}
              </p>
              <p class="has-text-centered" v-for="(price, idxx) in this.$store.getters.getVolumesDiscount(product.id)" :key="idxx" v-bind:class="{ 'bg-grey': idxx % 2 == 0 }">
                <span v-if="product.resource_type=='Lid'">{{ this.$t("message.pages.pricingPage.lids1") }} </span><span>{{price.volume_point}} {{ this.$t("message.pages.pricingPage.eachPieceText1") }}</span> {{ this.$t("message.pages.pricingPage.eachPieceText2") }} <span v-if="product.resource_type=='Cup'">{{price.price}}€ = {{(price.volume_point * price.price).toFixed(2)}}€</span> <span v-if="product.resource_type=='Lid'">{{price.price}}€</span>
              </p>
              <p class="has-text-centered" v-if="product.name == 'Kansi 80 mm'">
                {{ this.$t("message.pages.pricingPage.lids2") }} <span>1 {{ this.$t("message.pages.pricingPage.eachPieceText1") }}</span> {{ this.$t("message.pages.pricingPage.eachPieceText2") }} <span>0.06</span>€
              </p>
              <p class="has-text-centered" v-if="product.name == 'Kansi 90 mm'">
                {{ this.$t("message.pages.pricingPage.lids2") }} <span>1 {{ this.$t("message.pages.pricingPage.eachPieceText1") }}</span> {{ this.$t("message.pages.pricingPage.eachPieceText2") }} <span>0.07</span>€
              </p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <div class="content">
              <p class="has-text-centered font-secondary font-28">{{ this.$t("message.pages.pricingPage.suunniText") }} {{this.$store.getters.getDesignServiceFee.price}}€/design</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PmFooter />
  </section>
</template>

<script>
import PmNavbar from "../components/PmNavbar";
import PmFooter from "../components/PmFooter";

import { mapGetters } from 'vuex'

export default {
  name: "PricingListPage",
  components: {
    PmNavbar,
    PmFooter,
  },
  data() {
    return {}
  },
  methods: {
    ...mapGetters([
      'getProductDescription'
    ])
  },
  mounted() {},
  computed: {},
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.pricinglist-page {
  .cups-img {
    img {
      max-height: 128px;
    }
  }
  .bg-grey {
    background-color: #ebeff5;
  }
}
</style>
