<template>
  <section class="hero is-fullheight thanks-wrapper">
    <PmNavbar onlyBanner="true"/>
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-12 is-mobile has-text-left">
            <h2 v-if="status == 'PAID'" class="has-text-weight-bold">{{ this.$t("message.pages.thanksPage.lineOne") }}</h2>
            <h2 v-else-if="status == 'ORDERED'" class="has-text-weight-bold">{{ this.$t("message.pages.thanksPage.lineTwo") }}</h2>
            <h2 v-else>{{ this.$t("message.pages.thanksPage.lineThree") }}</h2>
            <br />
            <p v-if="status == 'PAID' || status == 'ORDERED'" >{{ this.$t("message.pages.thanksPage.lineFour") }}</p>
            <br /><br />
            <p v-if="status == 'PAID' || status == 'ORDERED'">{{ this.$t("message.pages.thanksPage.lineFifth") }} {{ email }}.</p>
            <p v-if="status == 'PAID' || status == 'ORDERED'">{{ this.$t("message.pages.thanksPage.lineSixth") }}</p>
            <div class="mt-6">
              <button @click="goToHome" class="button is-primary">{{ this.$t("message.pages.thanksPage.goHomepageButtonText") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PmFooter />
  </section>
</template>

<script>
import PmNavbar from '../components/PmNavbar'
import PmFooter from '../components/PmFooter'
import { mapState, mapMutations, mapActions } from 'vuex'
import { useState } from "vue-gtag-next";

export default {
  name: 'ThankYou',
  components: {
    PmNavbar,
    PmFooter,
  },  
  data() {
    return {
      email: null,
      status: null,
    }
  },
  computed: {
    ...mapState(['order', 'putContactData', 'postContactData']),
  },
  methods: {
    ...mapMutations({
      setReseting: 'setReseting',
      setSpinnerState: 'setSpinnerState'
    }),
    ...mapActions({
      getOrder: 'getOrder',
    }),
    goToHome() {
      this.$router.push('/')
    },
  },
  beforeMount() {
    this.getOrder().then(() => {
      let order = this.$store.getters.getOrderData
      if (['ORDERED', 'PAID', 'CANCEL'].indexOf(order['status']) === -1) {
        this.$router.push('/')
      } else {
        this.status = order.status
        this.email = this.postContactData.cust_email
        if (this.putContactData) this.email = this.putContactData.cust_email
        this.setReseting(true)
      }
      this.setSpinnerState(false)
    })
  },
  mounted() {
    if (this.$store.getters.getGoogleCookieEnabled) {
        const { property } = useState();
        property.value = {
          id: process.env.VUE_APP_GA_ID
        }
      }
    }
  }
</script>

<style lang="scss">
@import "../assets/sass/mybulma";
.thanks-wrapper {

}
</style>
