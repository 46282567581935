// define a mixin object
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    methods: {
        ...mapMutations([
          'setCartSpinnerState',
          'setNotificationActiveState',
          'setSpinnerState'
        ]),
        ...mapGetters([
            'getOrderData',
            'getSavedItems',
            'getToken',
            'getVolumesDiscount'
        ]),
        ...mapActions([
            'getAuthenticate',
            'postOrder',
            'postItem',
            'getOrder',
            'postRequestOrderForm',
        ]),
        getMinimumAmountForProduct(id) {
            return this.getVolumesDiscount()(id)[0].volume_point
        },
        validOrder(order) {
           if (order == null) {
               return false
           }
           if (order.id == undefined) {
               return false
           }
           return true
        },
        selectProductRequestForm(id) {
            this.setCartSpinnerState(true)
            this.setSpinnerState(true)
            let self = this
            this.getAuthenticate({})
                .then(() => {
                    let order = this.getOrderData()
                    if (this.validOrder(order)) {
                        this.postRequestOrderForm({
                            "amount": self.getMinimumAmountForProduct(id),
                            "product": id,
                            "order": order["order_number"],
                        }).then((data) => {
                            this.getOrder()
                            .then(() => {
                                self.setCartSpinnerState(false)
                                self.setSpinnerState(false)
                                this.$router.push({
                                    name: 'RequestOrderFormPage',
                                    params: {
                                        orderUuid: self.getOrderData().id,
                                        requestUuid: data.data.id,
                                        productId: id
                                    }
                                })
                            })                            
                        })
                    } else {
                        this.postOrder({})
                            .then(() => {
                                let order = this.getOrderData()
                                this.postRequestOrderForm({
                                    "amount": self.getMinimumAmountForProduct(id),
                                    "product": id,
                                    "order": order["order_number"],
                                })
                                    .then((data) => {
                                        this.getOrder()
                                            .then(() => {
                                                self.setCartSpinnerState(false)
                                                self.setSpinnerState(false)
                                                this.$router.push({
                                                    name: 'RequestOrderFormPage',
                                                    params: {
                                                        orderUuid: self.getOrderData().id,
                                                        requestUuid: data.data.id,
                                                        productId: id
                                                    }
                                                })
                                            })
                                    })
                            })
                    }
                })
            .catch(() => {
                this.$router.push({name: 'Landing'})
            })
        },
        selectProductEditor(id, resource, page) {
            let draft = true
            if (page == 'cart') {
                draft = false
            }
            let amount = this.getMinimumAmountForProduct(id)
            if (resource == 'Lid' ) {
                if (this.has_cups) {
                    amount = 50
                } else {
                    amount = 1000
                }
            }
            this.setCartSpinnerState(true)
            this.setSpinnerState(true)
            let self = this
            this.getAuthenticate({})
                .then(() => {
                    let order = this.getOrderData()
                    if (this.validOrder(order)) {
                        this.postItem({
                            "amount": amount,
                            "product": id,
                            "resourcetype": resource,
                            "order": order["order_number"],
                            "draft": draft
                        })
                            .then((data) => {
                                this.getOrder()
                                    .then(() => {
                                        self.setCartSpinnerState(false)
                                        self.setSpinnerState(false)
                                        if (page == 'editor') {
                                            this.$router.push({
                                                name: 'Editor',
                                                params: {
                                                    uuid: self.getOrderData().id,
                                                    product: id,
                                                    item: data.data.id
                                                }
                                            })
                                        } else if (page == 'cart') {
                                            this.$router.push({
                                              name: 'Cart',
                                              params: {
                                                uuid: self.getOrderData().id
                                              }
                                            })
                                        }
                                    })
                            })
                    } else {
                        this.postOrder({})
                            .then(() => {
                                let order = this.getOrderData()
                                this.postItem({
                                    "amount": amount,
                                    "product": id,
                                    "resourcetype": resource,
                                    "order": order["order_number"],
                                    "draft": draft
                                })
                                    .then((data) => {
                                        this.getOrder()
                                            .then(() => {
                                                self.setCartSpinnerState(false)
                                                self.setSpinnerState(false)
                                                if (page == 'editor') {
                                                    this.$router.push({
                                                        name: 'Editor',
                                                        params: {
                                                            uuid: self.getOrderData().id,
                                                            product: id,
                                                            item: data.data.id
                                                        }
                                                    })
                                                } else if (page == 'cart') {
                                                    this.$router.push({
                                                      name: 'Cart',
                                                      params: {
                                                        uuid: self.getOrderData().id
                                                      }
                                                    })
                                                }
                                            })
                                    })
                            })
                    }
                })
            .catch(() => {
                this.$router.push({name: 'Landing'})
            })
        }
    },
    computed: {
        products() {
          if (this.getOrderData() != null) return this.getSavedItems().length
          else return 0
        },
        has_cups() {
          for (let i = 0; i < this.getSavedItems().length; i++) {
            if (this.getSavedItems()[i].product.resource_type == 'Cup') {
              return true
            }
          }
          return false
        },
    }
}
