<template>
  <div class="notification pt-5">
    <p>{{ this.$t("message.modals.cookieNotification.textOne") }}</p>
    <p class="my-3">{{ this.$t("message.modals.cookieNotification.textTwo") }} <a @click="goToCookiePage">{{ this.$t("message.modals.cookieNotification.textTwoLink") }}</a></p>
    <p>{{ this.$t("message.modals.cookieNotification.textThree") }}</p>
    <div class="buttons-container is-flex is-justify-content-space-evenly mt-5 pb-3">
      <button class="pm-button pointer" @click="acceptAllCookies(false)">{{ this.$t("message.modals.cookieNotification.declineButton") }}</button>
      <button class="pm-button pointer" @click="acceptAllCookies(true)">{{ this.$t("message.modals.cookieNotification.acceptButton") }}</button>
    </div>
  </div>
</template>

<script>
import { useState } from "vue-gtag-next";

export default {
  name: "CookieNotification",
  components: {},
  data() {
    return {
      active: false,
    };
  },
  methods: {
    acceptAllCookies(acceptAll) {
      if (acceptAll) {
        this.$store.commit('setCookieNotificationShow', false)
        this.$store.commit('setGoogleCookieEnabled', true)
        const { property } = useState();
        property.value = {
          id: process.env.VUE_APP_GA_ID
        }
      } else {
        this.$store.commit('setCookieNotificationShow', false)
        this.$store.commit('setGoogleCookieEnabled', false)
      }
    },
    goToCookiePage() {
      this.$router.push({
        name: 'CookiePolicy'
      })
    },    
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.notification {
  position: fixed;
  bottom: 0;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  background-color: hsl(171deg 100% 41%);
  .buttons-container {
    @media screen and (max-width: 768px) {
      flex-direction: column;
      .pm-button:first-of-type {
        margin-bottom: 15px;
      }
    }    
  }
  .pm-button {
    font-size: 16px;
  }
  .pointer {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .notification {
    padding-right: 24px;
  }
}





</style>
