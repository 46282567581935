import { createApp } from 'vue'
import { router } from './router'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag-next"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faMinus, faUndo, faBorderStyle } from '@fortawesome/free-solid-svg-icons'
import 'croppie/croppie.css'
import { createI18n } from 'vue-i18n'
import messages from './i18n/messages'

const i18n = createI18n({
  locale: 'fi',
  fallbackLocale: 'en',
  messages,
})

const app = createApp(App);

app.use(router);
app.use(store)
app.use(VueAxios, axios)
app.use(i18n)

app.use(VueGtag); // we put google id once user pass the cookie wall  https://matteo-gabriele.gitbook.io/vue-gtag/v/next/bootstrap-options

library.add(faMinus, faPlus, faUndo, faBorderStyle)
app.mount('#app');