<template>
  <section class="hero is-fullheight tips-page">
    <PmNavbar />
    <div class="hero-body py-0">
      <div class="container light-bg">
        <div class="columns is-multiline p-5">
          <div class="column is-12">
            <h2 class="mb-3 font-secondary font-28">{{ this.$t("message.pages.tipsPage.title1") }}</h2>
            <h3 class="mb-3 font-secondary font-25">{{ this.$t("message.pages.tipsPage.title2") }}</h3>
            <p class="font-18 mb-3">{{ this.$t("message.pages.tipsPage.p1") }}</p>
            <div class="columns">
              <div class="column is-3 pb-0">
                <p>6 oz/175 ml</p>
              </div>
              <div class="column is-3 pb-0">
                <p>172 x 73 mm</p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3 py-0">
                <p>8 oz/240 ml</p>
              </div>
              <div class="column is-3 py-0">
                <p>182 x 85 mm</p>
              </div>
            </div>    
            <div class="columns">
              <div class="column is-3 py-0">
                <p>12 oz/350 ml</p>
              </div>
              <div class="column is-3 py-0">
                <p>193 x 110 mm</p>
              </div>
            </div>               
            <div class="columns">
              <div class="column is-3 py-0">
                <p>20 oz/590 ml</p>
              </div>
              <div class="column is-3 py-0">
                <p>202 x 162 mm</p>
              </div>
            </div>
            <p class="font-18">{{ this.$t("message.pages.tipsPage.p2") }}</p>
            <figure class="image mt-5">
              <img src="../assets/img/tips_img_1.png" />
            </figure>
            <p class="mt-1">{{ this.$t("message.pages.tipsPage.p3") }}</p>
            <h3 class="mt-5 mb-3 font-secondary font-25">{{ this.$t("message.pages.tipsPage.title3") }}</h3>
            <p class="font-18">{{ this.$t("message.pages.tipsPage.p4") }}</p>
            <figure class="image mt-5">
              <img src="../assets/img/tips_img_2.png" />
            </figure>
            <h3 class="mt-5 mb-3 font-secondary font-25">{{ this.$t("message.pages.tipsPage.title4") }}</h3>
            <p class="font 18">{{ this.$t("message.pages.tipsPage.p5") }}</p>
            <h3 class="mt-5 mb-3 font-secondary font-25">{{ this.$t("message.pages.tipsPage.title5") }}</h3>
            <p class="font 18">{{ this.$t("message.pages.tipsPage.p6") }}</p>
            <p class="font 18">{{ this.$t("message.pages.tipsPage.p7") }}</p>
            <p class="font 18">{{ this.$t("message.pages.tipsPage.p8") }}</p>
            <h3 class="mt-5 mb-3 font-secondary font-25">{{ this.$t("message.pages.tipsPage.title6") }}</h3>
            <p class="font 18">{{ this.$t("message.pages.tipsPage.p9") }}</p>
          </div>
        </div>          
      </div>
    </div>
    <PmFooter />
  </section>  
</template>

<script>
import PmNavbar from "../components/PmNavbar";
import PmFooter from "../components/PmFooter";

export default {
  name: "TipsPage",
  components: {
    PmNavbar,
    PmFooter,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  computed: {},
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.tips-page {
  .light-bg {
    background-color: $pm-light;
  }
  .container {
    min-height: 79vh;
  }
}
</style>
