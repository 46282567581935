<template>
  <section class="hero is-fullheight method-page">
    <PmNavbar />
    <div class="hero-body is-align-items-flex-start light-bg">
      <div class="container">
        
        <div class="columns is-multiline py-5 white-bg">
          <div class="column is-12">
            <h3 class="has-text-centered cstm-font-color font-secondary cstm-underline pt-5 pb-5">{{ this.$t("message.pages.designMethodPage.h3_1") }}</h3>
          </div>
          <div class="column is-12">
            <p class="has-text-centered cstm-font-color font-18">
              {{ this.$t("message.pages.designMethodPage.p_1") }}
            </p>
          </div>
        </div>

        <div class="columns is-multiline white-bg mt-5 pb-5">
          <div class="column is-12 has-text-centered pb-5">
            <p class="has-text-centered font-secondary font-18 py-5">{{ this.$t("message.pages.designMethodPage.p_2") }} {{this.selectedProduct?.name}}</p>
            
            <p class="has-text-centered mt-3" v-if="this.$route.params.productId != 7">{{ this.$t("message.pages.designMethodPage.p_3") }}</p>
            <button class="button pm-button my-5" v-if="this.$route.params.productId != 7" @click="selectProductEditor(this.selectedProduct.id, this.selectedProduct.resource_type, 'editor')">{{ this.$t("message.pages.designMethodPage.button_1") }}</button>

            <p class="has-text-centered mt-5">{{ this.$t("message.pages.designMethodPage.p_4") }}</p>
            <button class="button pm-button mt-5" @click="selectProductRequestForm(this.selectedProduct.id)">{{ this.$t("message.pages.designMethodPage.button_2") }}</button>
            
          </div>
        </div>

      </div>
    </div>
    <PmFooter />
  </section>
</template>

<script>
import PmNavbar from '../components/PmNavbar'
import PmFooter from '../components/PmFooter'
import addProductMixin from "@/mixins/addProduct"

export default {
  name: "DesignMethodPage",
  components: {
    PmNavbar,
    PmFooter,
  },
  mixins: [addProductMixin],
  data() {
    return {
      selectedProduct: null
    }
  },
  methods: {},
  mounted() {
    this.selectedProduct = this.$store.getters.getProducts.find(el => el.id == this.$route.params.productId)
  },
  computed: {},
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.method-page {
  .cstm-font-color {
    color: $font-color-primary;
  }  
  .white-bg {
    background-color: #ffffff;
  }
  .light-bg {
    background-color: #f0efe4;
  }
}
</style>
