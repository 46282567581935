import convert from "color-convert";

export default {
  methods: {
    hslToCmyk(h, s, l) {
      // Ensure h, s, and l are in the correct range
      h = (h % 360 + 360) % 360
      s = Math.max(0, Math.min(1, s))
      l = Math.max(0, Math.min(1, l))

      // Convert HSL to RGB
      let rgb = convert.hsl.rgb([h, s * 100, l * 100])

      // Convert RGB to CMYK
      let cmyk = convert.rgb.cmyk(rgb)

      return {
          cyan: Math.round(cmyk[0]),
          magenta: Math.round(cmyk[1]),
          yellow: Math.round(cmyk[2]),
          black: Math.round(cmyk[3])
      }
    },
    parseHSL(colorString) {
      // Use regular expression to extract values from the HSL color string
      let match = colorString.match(/hsl\(\s*([\d.]+)\s*([\d.]+)%\s*([\d.]+)%\s*\)/)

      if (!match) {
          // Handle invalid color string
          throw new Error('Invalid HSL color string')
      }

      // Extract values from the match
      let h = parseInt(match[1], 10)
      let s = parseFloat(match[2]) / 100
      let l = parseFloat(match[3]) / 100

      return { h, s, l }
    }
  }
}
