<template>
  <div class="hero-foot pm-foot py-4">
    <nav class="tabs">
      <div class="container">
        <ul class="is-justify-content-center is-hidden-mobile">
          <li><img style="max-height: 45px" src="../assets/img/Avainlippu_tehty_Suomessa.jpeg"/></li>
          <li class="pwr-by font-15">{{ this.$t("message.components.footer.power") }}</li>
          <li><a href="https://packagemedia.com/" target="_blank"><img style="max-height: 75px; padding-left: 20px; padding-right: 20px;" src="../assets/img/pm-logo.png"/></a></li>
          <li><img style="max-height: 45px" src="../assets/icons/euroopan.png"/></li>
          <li><a @click="customOpenModal" style="border-bottom:0;">{{ this.$t("message.components.footer.tieto") }}</a></li>
        </ul>
        <div class="columns is-hidden-tablet">
          <div class="column is-12 has-text-centered is-paddingless mt-3"><img style="max-height: 45px" src="../assets/img/Avainlippu_tehty_Suomessa.jpeg"/></div>
          <div class="column is-12 has-text-centered is-paddingless">{{ this.$t("message.components.footer.power") }}</div>
          <div class="column is-12 has-text-centered is-paddingless"><a href="https://packagemedia.com/" target="_blank"><img style="max-height: 45px" src="../assets/img/pm-logo.png"/></a></div>
          <div class="column is-12 has-text-centered is-paddingless"><img style="max-height: 45px" src="../assets/icons/euroopan.png"/></div>
          <div class="column is-12 has-text-centered is-paddingless"><a @click="customOpenModal" style="border-bottom:0;">{{ this.$t("message.components.footer.tieto") }}</a></div>
        </div>
      </div>
    </nav>
  </div>
  <PrivacyPolicy ref="privacyModal"/>
</template>

<script>
import PrivacyPolicy from '../modals/PrivacyPolicy'

export default {
  name: "PmFooter",
  components: {
    PrivacyPolicy,
  },
  data() {
    return {};
  },
  methods: {
    customOpenModal() {
      window.scrollTo(0, 0);
      this.$refs.privacyModal.showModal();
    }
  },
  mounted() {},
  computed: {},
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.pm-foot {
  border-top: 10px solid $pm-yellow;
  .pwr-by {
    color: #707070;
  }
  .pm-span-1 {

  }
  .pm-span-2 {

  }
}
</style>
