<template>
  <div class="menu hero-head">
    <div class="container">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <div class="is-flex mt-0 is-justify-content-space-between" :class="{'home-image': !onlyBanner }">
          <a class="navbar-item p-0 pm-title" href="/">
            <img src="../assets/img/PMpro-logo.png">
          </a>

          <!--div v-if="!onlyBanner" class="buttons has-addons mr-5 is-hidden-mobile">
            <button @click="switchlanguage('fi')" class="button i18n-buttons" :class="{ 'i18n-selected': this.$i18n.locale == 'fi' }">FI</button>
            <button @click="switchlanguage('en')" class="button bl-1 i18n-buttons" :class="{ 'i18n-selected': this.$i18n.locale == 'en' }">EN</button>
          </div-->
        </div>

        <div v-if="!onlyBanner" class="popover is-popover-bottom" @click="clickAddProductModal()">
          <OnClickOutside @trigger="addProductModal=false">
            <a class="add-product-button mt-0 popover-trigger">
              <i class="fa fa-plus font-blue" aria-hidden="true"></i>
              <!--img src="../assets/img/lisaatuote.png" class="is-hidden-mobile"/-->
            </a>
            <add-product-modal v-if=addProductModal></add-product-modal>
          </OnClickOutside>
        </div>

        <div v-if="!onlyBanner" class="popover is-popover-bottom" @click="clickCartModal()">
          <OnClickOutside @trigger="openCartModal=false">
            <a class="shopping-cart-button mt-0 popover-trigger is-relative is-flex">
              <span v-if="$store.getters.getCartSpinnerState">
                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </span>
              <span v-else>
                <span class="product-length-bubble" v-if="getProductsLength>0">{{getProductsLength}}</span>
                <i v-if="!$store.getters.getCartSpinnerState" class="fa fa-shopping-cart font-blue" aria-hidden="true"></i>
              </span>
            </a>
            <cart-modal v-if=openCartModal></cart-modal>
          </OnClickOutside>
        </div>


        <OnClickOutside @trigger="menuActive=false">
          <a v-if="!onlyBanner" role="button" class="navbar-bigburger burger"
             :class="{'is-active': menuActive}"
             @click="openInfoModal()"
             aria-label="menu" aria-expanded="false"
             data-target="navbarBasicExample">
            <span aria-hidden="true" :class="{'is-active': menuActive}"></span>
            <span aria-hidden="true" :class="{'is-active': menuActive}"></span>
            <span aria-hidden="true" :class="{'is-active': menuActive}"></span>
          </a>
        </OnClickOutside>
      </div>

      <div v-if="!onlyBanner" id="navbarBasic" class="navbar-item has-dropdown" v-show="menuActive">
        <div class="navbar-start">
          <a class="navbar-item" @click="closeMenu(); $router.push({name: 'ShopList'})">
            {{ this.$t("message.components.navbar.optionTuotteet") }}
          </a>
          <a class="navbar-item" @click="closeMenu(); $router.push({name: 'TipsPage'})">
            {{ this.$t("message.components.navbar.optionVinkk") }}
          </a>

          <a class="navbar-item" @click="closeMenu(); $router.push({name: 'FaqPage'})">
            {{ this.$t("message.components.navbar.optionUsein") }}
          </a>

          <a class="navbar-item" @click="closeMenu(); $router.push({name: 'TermsPage'})">
            {{ this.$t("message.components.navbar.optionTilaus") }}
          </a>

          <a class="navbar-item" @click="closeMenu(); $router.push({name: 'AboutUs'})">
            {{ this.$t("message.components.navbar.optionPmpro") }}
          </a>

          <a class="navbar-item" @click="closeMenu(); $router.push({name: 'PricingListPage'})">
            {{ this.$t("message.components.navbar.optionsHinnasto") }}
          </a>                  

        </div>
      </div>
      <!--div v-if="!onlyBanner" class="lang-container is-flex is-hidden-tablet is-justify-content-end">
        <div class="buttons mr-5">
          <button @click="switchlanguage('fi')" class="button i18n-buttons" :class="{ 'i18n-selected': this.$i18n.locale == 'fi' }">FI</button>
          <button @click="switchlanguage('en')" class="button bl-1 i18n-buttons" :class="{ 'i18n-selected': this.$i18n.locale == 'en' }">EN</button>
        </div>
      </div-->
    </nav>
    </div>
  </div>
  <ContactModal ref="contactModal"/>  
</template>

<script>

import cartModal from "@/modals/cartModal"
import addProductModal from "@/modals/addProductModal"
import ContactModal from "@/modals/ContactModal"
import { OnClickOutside } from '@vueuse/components'
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "PmNavbar",
  components: {
    cartModal,
    addProductModal,
    OnClickOutside,
    ContactModal,
  },
  props: {
    onlyBanner: Boolean
  },
  data() {
    return {
      menuActive: false,
      openCartModal: false,
      addProductModal: false
    };
  },
  methods: {
    ...mapGetters([
      'getSavedItems',
      'getNonDraftOrderForms',
      'getOrderData'
    ]),
    ...mapMutations([
      'seti18n'
    ]),
    switchlanguage(locale) {
      if (this.$i18n.locale == locale) return;
      this.$i18n.locale = locale
      this.seti18n(locale)
    },
    closeMenu() {
      this.menuActive = false
    },
    openInfoModal() {
      this.addProductModal = false
      this.openCartModal = false
      this.menuActive = !this.menuActive
    },
    clickCartModal() {
      this.addProductModal = false
      if (this.openCartModal) {
        this.openCartModal = false
      } else {
        this.openCartModal = true
      }
    },
    clickAddProductModal() {
      this.openCartModal = false
      if (this.addProductModal) {
        this.addProductModal = false
      } else
        this.addProductModal = true
    }
  },
  computed: {
    getProductsLength() {
      if(this.onlyBanner) return;
      let order = this.getOrderData
      if (!order) {
        return 0
      }
      return this.getSavedItems().length + this.getNonDraftOrderForms().length
    },
  },

};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.menu {
  background: white;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  width: 100%;

  .i18n-buttons {
    font-size: 20px;
    font-family: $font-primary;
  }

  .bl-1 {
    border-left: 1px solid $font-color-primary;
  }

  .i18n-selected {
    font-family: $font-secondary;
  }

  .pm-title img {
    aspect-ratio: 8/3;
    height: 100%;
    max-height: 70px;
    @media screen and (max-width: 768px) {
      max-height: 45px;
    }
  }

  .pm-logo  {
    &:hover {
      text-decoration: none;
    }
  }

  @media only screen and (min-width: 768px) {
    border-bottom: 2px solid #707070;
  }

  .navbar-bigburger {
    color: $pm-blue;
    padding-right: 200px;
    span:nth-child(3) {
      top: calc(50% - -25px);
    }
    span:nth-child(2) {
      top: calc(50% - -15px);
    }
    span:nth-child(1) {
      top: calc(50% - -5px);
    }
    @media (max-width: 768px) {
      width: 100px;
      padding-right: 20px;
      span {
        height: 3px;
        width: 19px;
      }
      span:nth-child(3) {
        top: calc(50% - -23px);
      }
      span:nth-child(2) {
        top: calc(50% - -17px);
      }
      span:nth-child(1) {
        top: calc(50% - -11px);
      }
      span.is-active:nth-child(3) {
        top: calc(50% - -26px);
      }
      span.is-active:nth-child(1) {
        top: calc(50% - -6px);
      }
    }
  }
  .navbar-bigburger:hover {
    background-color: white;
  }
  .navbar {
    max-width: 1640px;
    width: 100%;
    position: relative;

    @media (max-width: 768px) {
      padding-bottom: 10px;
      border-bottom: 2px solid #707070;
      margin-bottom: 10px;
    }

    .navbar-start {
      padding: 10px 20px;
      .navbar-item {
        text-align: left;
        font-size: 18px;
        margin-bottom: 15px;
        @media (max-width: 768px) {
          text-align: center;
        }
        &:hover {
          color: $pm-blue;
          background-color: #F0EFE4;
        }
      }
    }

    .home-image {
      width: 100%;
      margin: 0 auto;
    }

    .navbar-burger {
      //height: 80px;
      background: white;
    }

    #navbarBasic {
      position: absolute;
      background: white;
      border: 1px solid #707070;
      right: -12px;
      top: 101px;
      width: 400px;
      z-index: 1;
      @media (max-width: 768px) {
        top: 88px;
        width: 100%;
        right: 0;
      }
    }

  }
}

.example-header {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  img {
    width: 400px;
    height: 196px;
  }
}

.example-header-editor {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  img {
    width: 400px;
    height: 196px;
    margin-left: 250px;
  }
}

.shopping-cart-button {
  color: $primary;
  font-size: 20px;
  line-height: 100px;
  @media only screen and (min-width: 768px) {
    font-size: 30px;
    padding-right: 110px;
    margin-right: 100px;
    margin-left: 40px;
    padding-left: 50px;
  }

  svg {
    margin-top: 19px;
    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }
}

.add-product-button {
  color: $primary;
  font-size: 20px;
  line-height: 100px;
  @media only screen and (min-width: 768px) {
    font-size: 30px;
    margin-right: 100px;
    padding-right: 110px;
    margin-left: 40px;
    padding-left: 50px;
    svg {
      margin-top: 19px !important;
    }
  }

  svg {
    margin-top: 40px;
    margin-right: 25px;
  }
}

.add-product-button:hover {
  color: $primary;
}


.shopping-cart-button:hover {
  color: $primary;
}

.popover {
  width: 100px;
  @media only screen and (min-width: 1000px) {
    border-right: 1px solid #707070;
    width: 210px;
  }
}

.product-length-bubble {
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  box-sizing: border-box;
  position: absolute;
  width: 26px;
  height: 25px;
  vertical-align: middle;
  line-height: 23px;
  left: 56px;
  top: 10px;
  color: white;
  background-color: red;
  font-weight: bold;
  font-family: sans-serif;

  @media screen and (max-width: 768px) {
    transform: scale(0.8);
    //width: 26px;
    top: 18px;
    left: -1px;
    font-size: 8px;
  }
}

.popover-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  pointer-events: none;
}

</style>
