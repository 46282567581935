import en from './en'
import fi from './fi'

export default {
  en: {
    message: en
  },
  fi: {
    message: fi
  }
}